import React from 'react'
import { Link } from 'react-router-dom'

interface ContactUsType{
    style: string
}

const ContactUs:React.FC<ContactUsType> = ({style}) => {
    return (
        <Link to="/contact" className={style + " w-full text-white-600 p-3 text-center rounded-lg"}>Contact us</Link>
    )
}

export default ContactUs